<template>
  <div class="login-container">
    <h2>Login Page</h2>
    <form @submit.prevent="login">
      <div>
        <label for="username">Username:</label>
        <input type="text" id="username" v-model="username" required />
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="password" id="password" v-model="password" required />
      </div>
      <button type="submit">Login</button>
      <p v-if="error" style="color: red;">{{ error }}</p>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      error: '',
      token: ''
    };
  },
  methods: {
    async login() {
      this.error = ''; // 先清空之前可能存在的错误信息
      try {
        const response = await this.$axios.post('/login', {
          username: this.username,
          password: this.password
        });

        if (response.status === 200) {
          const jwt = response.data;
          this.token = jwt;
          localStorage.setItem('token', this.token);
          await this.$router.push('/UploadData'); // 登录成功后跳转到/UploadData路径
        } else {
          // 确保后端返回的错误消息是字符串类型
          this.error = typeof response.data.message === 'string' ? response.data.message : '用户名或密码错误';
        }
      } catch (error) {
        // 更详细地区分不同类型的错误并提供针对性的错误提示
        if (error.response && error.response.status === 401) {
          // 假设后端对于账号密码错误返回401状态码
          this.error = '用户名或密码错误，请检查后重试';
        } else if (error.response) {
          // 其他后端返回的问题状态码情况
          this.error = `登录失败，错误码: ${error.response.status}，请稍后重试`;
        } else if (error.message) {
          // 比如网络错误等其他前端相关错误
          this.error = `登录失败：${error.message}，请检查网络连接后重试`;
        } else {
          // 兜底的默认错误消息
          this.error = '登录失败，请重试';
        }

        // 如果是401错误且当前不在登录页面，尝试导航到登录页面
        if (error.response && error.response.status === 401 && this.$route.path !== '/login') {
          this.$router.push('/login');
        }
      }
    }
  }
};
</script>

<style>
.login-container {
  /* 设置背景色，这里使用浅灰色作为示例 */
  background-color: #f4f4f4;
  /* 添加边框，样式可以根据喜好调整 */
  border: 1px solid #ccc;
  /* 使用flex布局来实现垂直和水平居中 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* 设置容器的宽度和高度，可以根据实际需求调整 */
  width: 300px;
  height: 300px;
  /* 设置容器距离顶部的距离，这里将其定位在屏幕垂直方向的中间位置 */
  margin-top: 150px;
}

/* 对输入框和按钮等元素也可以添加一些样式来使其更美观 */
input[type="text"],
input[type="password"] {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
</style>