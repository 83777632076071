import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginView from '@/views/ZG/LoginSys.vue';
import GetResultView from '@/views/ZG/GetResult.vue';
import UploadDataView from '@/views/ZG/UploadData.vue';
import ProfileView from '@/views/ZG/VocationProfile.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/UploadData'
  },
  {
    path: '/GetResult',
    name: 'GetResult',
    component: GetResultView
  },
  {
    path: '/UploadData',
    name: 'UploadData',
    component: UploadDataView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired =!publicPages.includes(to.path);
  const token = localStorage.getItem('token');

  if (authRequired &&!token) {
    // 如果需要认证但没有token，直接跳转到登录页面
    next('/login');
  } else if (to.path === '/login' && token) {
    // 如果已经登录（有token）且要跳转到登录页面，跳转到首页
    next('/');
  } else {
    // 其他情况正常放行
    next();
  }
});

export default router;