<template>
  <div>
    <button @click="getTeacherStatusSalary">按教师身份划分得到工资计算结果</button>
    <button @click="getAllSalary">得到职高所有人工资结果</button>
  </div>
</template>

<script>
export default {
  methods: {
    async getTeacherStatusSalary() {
      try {
        const response = await this.$axios({
          method: 'get',
          url: '/vocation/GetTeacherStatusSalary',
          responseType: 'blob' // 重要，处理返回的文件流
        });
        // 创建一个可下载的链接
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'teacher_status_salary.xlsx'); // 可以自定义下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // 释放掉blob对象使用的内存
      } catch (error) {
        console.error('下载失败:', error);
        this.$message.error('下载失败，请检查网络或链接合法性');
      }
    },
    async getAllSalary() {
      try {
        const response = await this.$axios({
          method: 'get',
          url: '/vocation/GetSalary',
          responseType: 'blob' // 重要，处理返回的文件流
        });
        // 创建一个可下载的链接
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'all_salary.xlsx'); // 可以自定义下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // 释放掉blob对象使用的内存
      } catch (error) {
        console.error('下载失败:', error);
        this.$message.error('下载失败，请检查网络或链接合法性');
      }
    },
  },
};
</script>