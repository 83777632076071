<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px">
      <!-- 上传教师课时费Excel文件 -->
      <el-form-item label="上传教师课时费Excel文件">
        <el-upload
            class="upload-excel"
            ref="upload"
            action="#"
            :auto-upload="false"
            :on-change="handleFileChange"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传 Excel 文件 (.xlsx, .xls)</div>
        </el-upload>
      </el-form-item>

      <el-form-item label="选择教师课时费日期">
        <el-date-picker
            v-model="form.date"
            type="month"
            placeholder="选择年月"
            format="yyyy-MM"
            value-format="yyyy-MM"
        ></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="success" @click="submitTeacherForm">提交教师课时费</el-button>
      </el-form-item>

      <!-- 上传考勤表Excel文件 -->
      <el-form-item label="上传考勤表Excel文件">
        <el-upload
            class="upload-excel"
            ref="uploadAttendance"
            action="#"
            :auto-upload="false"
            :on-change="handleFileChangeAttendance"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传 Excel 文件 (.xlsx, .xls)</div>
        </el-upload>
      </el-form-item>

      <el-form-item label="选择考勤表日期">
        <el-date-picker
            v-model="form.attendanceDate"
            type="month"
            placeholder="选择年月"
            format="yyyy-MM"
            value-format="yyyy-MM"
        ></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="success" @click="submitAttendanceForm">提交考勤表</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        file: null,
        date: null,
        attendanceFile: null,
        attendanceDate: null,
      },
    };
  },
  methods: {
    handleFileChange(file) {
      this.form.file = file.raw;
    },
    handleFileChangeAttendance(file) {
      this.form.attendanceFile = file.raw;
    },
    submitTeacherForm() {
      if (!this.form.file || !this.form.date) {
        this.$message.error('请确保文件和日期都已经选择');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.form.file);
      formData.append('date', this.form.date);

      this.$axios({
        method: 'post',
        url: '/vocation/TeacherXiaoBao',
        data: formData,
        responseType: 'blob', // 重要，处理返回的文件流
      })
          .then((response) => {
            // 创建一个可下载的链接
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'response.xlsx'); // 可以自定义下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url); // 释放掉blob对象使用的内存
          })
          .catch((error) => {
            console.error('提交失败:', error);
            this.$message.error('提交失败');
          });
    },
    submitAttendanceForm() {
      if (!this.form.attendanceFile || !this.form.attendanceDate) {
        this.$message.error('请确保考勤文件和日期都已经选择');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.form.attendanceFile);
      formData.append('date', this.form.attendanceDate);

      this.$axios({
        method: 'post',
        url: '/vocation/KaoQin',
        data: formData,
      })
          .then((response) => {
            console.log(response.data)
            this.$message.success('考勤表上传成功');
          })
          .catch((error) => {
            console.error('考勤表提交失败:', error);
            this.$message.error('考勤表提交失败');
          });
    },
  },
};
</script>

<style scoped>
.upload-excel .el-upload {
  display: block;
}
</style>